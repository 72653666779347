/** @jsx jsx */

import { createElement } from 'react';
import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import Slider from "react-slick"

import theme from "../gatsby-plugin-theme-ui"

export const MobileItemsCarousel = ({ items, component, itemProps }) => {
  return (
    <Slider
      arrows={false}
      dots={false}
      infinite={false}
      autoplay={false}
      swipeToSlide={true}
      slidesToShow={1.15}
      sx={{
        "div.slick-list": {
          pr: "0 !important",
          pl: `${(100 - theme.mobilePageContentWidth) / 2}% !important`,
        },

      }}
    >
      {items.map((item, i) => {

        return createElement(
          component,
          {
            key: i,
            ...item,
            ...itemProps
          }
        )
      })}
    </Slider>
  )
}
MobileItemsCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  component: PropTypes.func,
  itemProps: PropTypes.object,
}
