// Returns height styles based on media queries for a range
// of common mobile device heights
// Need to use this because vh units are not reliable on mobile
// and can cause ugly flicker and re-rendering problems
export const heightFromDeviceHeight = fraction => {
  // Set base height tall enough for the smallest phones
  const baseHeight = 570

  return {
    height: `${baseHeight * fraction}px`,
    minHeight: `${baseHeight * fraction}px`,
    "@media screen and (min-height: 640px)": {
      height: `${660 * fraction}px`,
      minHeight: `${660 * fraction}px`,
    },
    "@media screen and (min-height: 736px)": {
      height: `${740 * fraction}px`,
      minHeight: `${740 * fraction}px`,
    },
    // iPhone X/XS, XR, XS Max and Pixel 2 XL
    // iPhone XR and XS are almost 900px in height, so
    // having full device height for a component for them
    // wouldn't be that nice either, hence we keep the height at a lower range
    "@media screen and (min-height: 812px)": {
      height: `${823 * fraction}px`,
      minHeight: `${823 * fraction}px`,
    },
    "@media screen and (min-height: 900px)": {
      height: `${900 * fraction}px`,
      minHeight: `${900 * fraction}px`,
    },
  }
}
