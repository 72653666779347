/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import "./CarouselIndicator.css"

export const CarouselIndicator = ({ index }) => {
  return (
    <div
      key={index}
      sx={{
        display: "inline-block",
        minWidth: "20px",
        maxWidth: "80px",
        width: "100%",
        height: "2px",
        bg: "white",
      }}
      role="button"
      aria-label={`Carousel indicator ${index}`}
      tabIndex={0}
    />
  )
}
CarouselIndicator.propTypes = {
  index: PropTypes.number.isRequired,
}
