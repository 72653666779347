/** @jsx jsx */

import PropTypes from "prop-types"
import { jsx, Flex, IconButton } from "theme-ui"
import { transparentize } from "@theme-ui/color"
import { BiChevronRight, BiChevronLeft } from "react-icons/bi"

export const CarouselArrow = ({ onClick, direction }) => (
  <Flex
    sx={{
      // Hide the arrow on mobile since the swipe gesture is more intuitive
      display: ["none", "none", "flex"],
      height: "100%",
      cursor: "pointer",
      position: "absolute",
      top: 0,
      ...(direction === "right"
        ? { right: 0, justifyContent: "flex-end" }
        : { left: 0, justifyContent: "flex-start" }),
      zIndex: 1,
      backgroundColor: transparentize("gray", 1),
      transition: "all 0.3s ease-out",
      "&:hover": {
        backgroundColor: transparentize("gray", 0.7),
      },
    }}
  >
    <IconButton
      onClick={onClick}
      sx={{
        my: "auto",
        width: [null, null, "40px", "50px"],
        height: [null, null, "40px", "50px"],
        cursor: "inherit",
        position: "relative",
        p: 0,
        // This will control the react-icon size
        fontSize: [null, null, "25px", "35px"],
        opacity: [0, 7, 7],
      }}
      aria-label={`Carousel ${direction} arrow`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="100"
        height="100"
      >
        <rect sx={{ fill: "gray" }} width="100" height="100" rx="4" />
      </svg>
      {direction === "right" ? (
        <BiChevronRight
          sx={{
            position: "absolute",
            top: [null, null, "calc((40px - 25px)/2)", "calc((50px - 35px)/2)"],
            fill: "white",
          }}
        />
      ) : (
        <BiChevronLeft
          sx={{
            position: "absolute",
            top: [null, null, "calc((40px - 25px)/2)", "calc((50px - 35px)/2)"],
            fill: "white",
          }}
        />
      )}
    </IconButton>
  </Flex>
)
CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(["right", "left"]),
}

export const renderArrowProps = {
  // eslint-disable-next-line react/display-name
  nextArrow: <CarouselArrow direction="right" />,
  // eslint-disable-next-line react/display-name
  prevArrow: <CarouselArrow direction="left" />,
}
